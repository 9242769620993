.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s $quint-ease-out;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.screen-enter-active {
    transition: opacity 1s $quint-ease-in-out 0.6s;
}

.screen-leave-active {
    transition: opacity 0.6s $quint-ease-in-out;
}

.screen-enter,
.screen-leave-to /* .screen-leave-active below version 2.1.8 */ {
    opacity: 0;
}


.machine-enter-active,
.machine-leave-active {
    transition: opacity 0.4s $quint-ease-out;
}

.machine-enter,
.machine-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}