.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    border: 1px solid $font-color;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    @include canhover {
        transition: color 0.4s $quint-ease-out, background-color 0.4s $quint-ease-out;

        &:hover {
            background-color: $font-color;
            color: $white;
        }
    }

    &--small {
        display: inline-flex;
        width: auto;
        padding: 0 25px;
        height: 50px;
        background-color: $font-color;
        color: $white;

        @include canhover {
            transition: color 0.4s $quint-ease-out, background-color 0.4s $quint-ease-out, border-color 0.4s $quint-ease-out;
    
            &:hover {
                border-color: $white;
                background-color: $white;
                color: $font-color;
            }
        }
    }

    &--dark {
        border: 1px solid #26211e;
        color: #26211e !important;
        
        @include canhover {
            transition: color 0.4s $quint-ease-out, background-color 0.4s $quint-ease-out;
    
            &:hover {
                background-color: #26211e;
                color: $white !important;
            }
        }
    }
}

.btn-3d {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69.2592%;
    height: 11%;
    font-size: 100%;
    font-weight: 700;
    text-transform: uppercase;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
    cursor: pointer;
    z-index: 800;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    
    .btn-3d__lower {
        position: absolute;
    }

    .btn-3d__upper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $off-black;
        border: 2px solid $white;
        color: $white;
        border-radius: 25rem;
        box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.4);
        z-index: 900;
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
        border-radius: 25rem;
        transform: translate3d(0, 1.2em, -1em);
        transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
        box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.6);
        z-index: 820;
    }
    
    &--inverse {
        .btn-3d__upper {
            background: $white;
            color: $off-black;
        }

        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $off-black;
            border-radius: 25rem;
            transform: translate3d(0, 1.2em, -1em);
            transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
            box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
            border: 1px $white solid;
        }
    }

    @include canhover {
        &:hover {
            transform: translate(0, 0.4em);

            &::before {
                transform: translate3d(0, 0.8em, -1em);
            }
        }
    }

    &:active {
        transform: translate(0em, 0.75em);
        
        &::before {
            transform: translate3d(0, 0, -1em);
        }
    }
}

.small-link {
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    color: $white;
    margin-top: 18px;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.4);
    -webkit-tap-highlight-color: transparent;
    outline: none;
}