.screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: rgba($color: $white, $alpha: 0.95);
    font-size: 2.8vw;

    @media screen and (min-width: 768px) and (orientation: portrait) {
        font-size: 1.925vw;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        font-size: 1.2vw;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        font-size: 0.85vw;
    }
}

.screen__logo {
    position: absolute;
    top: 38%;
    left: 50%;
    display: block;
    width: 34.7222%;
    height: auto;
    margin-left: -17.36%;
}

.screen__small-title {
    text-transform: uppercase;
    font-size: 130%;
    line-height: 1;
    font-weight: bold;
    color: rgba($color: $white, $alpha: 0.95);
    text-shadow: 0px 0px 3px rgba(255,255,255,0.6);
    margin-top: 1%;
}

.screen-reveal {
    opacity: 0;
}

.screen__section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index: 10;

    &--start {
        opacity: 0;
    }
}

.screen__loading-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80.324%;
    height: 1.8519%;
    border: 1px solid $white;
    margin-top: 110%;
    box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.6);
}

.screen__loading-bar-text {
    position: absolute;
    right: 0;
    top: -160%;
    font-size: 46%;
    font-weight: bold;
    text-shadow: 0px 0px 3px rgba(255,255,255,0.6);
    line-height: 1.15;
}

.screen__loading-bar-progress {
    position: absolute;
    width: 99%;
    height: 66%;
    background-color: $white;
    transform: scaleX(0);
    transform-origin: left;
}

.screen__start-logo {
    display: block;
    width: 80.8333%;
    height: auto;
    margin: 2% 0 3%;
    animation: flickerMovement 1.6s infinite;
}

.screen__start-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 40%;
}

.screen__start-btn {
    display: none;
    margin-top: auto;
    margin-bottom: 14%;
}

.screen__steps {
    position: absolute;
    top: 17%;
    font-size: 120%;
    font-family: $title-font;
    font-weight: 800;
    text-shadow: 0px 0px 3px rgba(255,255,255,0.6);
}

.screen__tutorial-title {
    position: absolute;
    line-height: 0.88;
    top: 56.5%;
}

.screen__tutorial-image {
    position: absolute;
    top: 27%;
    display: block;
    width: 42.5463%;
    height: auto;

    &--02 {
        top: 25%;
        width: 40.1852%;
    }

    &--03 {
        top: 24.2%;
        width: 24.8148%;
    }

    &--04 {
        top: 24%;
        width: 46%;
    }
}

.screen__loading-title {
    position: absolute;
    top: 61%;
}

.screen__loading-animation {
    position: absolute;
    width: 92%;
    top: -2%;
    left: 52.2%;
    transform: translateX(-50%);
}

.screen__generate-animation {
    position: absolute;
    width: 150%;
    top: -20.2%;
    left: 54.15%;
    transform: translateX(-50%);
}

.screen__tutorial-navigation {
    position: absolute;
    top: 87%;
    width: 85.3704%;
    display: flex;
    justify-content: space-between;
}

.screen__tutorial-navigation-btn {
    width: 40%;
    font-size: 120%;
    border: 1px solid rgba($color: $white, $alpha: 0.9);
    text-transform: uppercase;
    font-family: $medium-font;
    text-shadow: 0px 0px 3px rgba(255,255,255,0.6);
    border-radius: 20rem;
    padding: 2% 0;
    line-height: 1;
    box-shadow: 0px 0px 3px 0px rgba(255,255,255,0.6);
    cursor: pointer;

    @include canhover {
        transition: color 0.4s $quint-ease-out, background-color 0.4s $quint-ease-out;

        &:hover {
            background-color: $white;
            color: $off-black;
        }
    }

    &--disabled {
        opacity: 0.5;
        cursor: default;

        @include canhover {
            transition: none;
    
            &:hover {
                background-color: transparent;
                color: $white;
            }
        }
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
}

.screen__start-selection-title {
    position: absolute;
    top: 57%;
}

.screen__start-selection-image {
    position: absolute;
    display: block;
    width: 20.6481%;
    height: auto;
    top: 25%;
}

.screen__start-selection-btn {
    margin-top: auto;
    margin-bottom: 14%;
}

.screen__selection-overview-title {
    position: absolute;
    top: 18%;
}

.screen__title {
  text-transform: uppercase;
  font-size: 200%;
  line-height: 1;
  font-weight: bold;
  color: rgba($color: $white, $alpha: 0.95);
  text-shadow: 0px 0px 3px rgba(255,255,255,0.6);

  &--question {
    top: 22%;
    font-size: 220%;
  }
}

.screen__selection-overview-artist {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-grey;
    width: 100%;
    font-size: 110%;
    line-height: 1;
    color: $white;
    padding: 4% 0;
    margin-bottom: 15px;
    text-transform: uppercase;
    cursor: pointer;
    
    &:last-of-type {
        margin-bottom: 0;
    }
}

.screen__form-title {
    position: absolute;
    top: 18%;
}

.screen__form-form {
    position: absolute;
    top: 34.5%;
}

.screen__generate-image {
    position: absolute;
    top: 23%;
    display: block;
    width: 42.4074%;
    height: auto;
}

.screen__generate-btn {
    position: absolute;
    top: 76%;
}

.screen__answers {
  position: absolute;
  width: 100%;
  top: 48%;
  text-align: left;
  text-transform: uppercase;
  padding: 0 8%;
  font-size: 110%;
}

.screen__answers-answer {
  padding: 2% 3%;
  margin-bottom: 2%;
  cursor: pointer;

  &--selected {
    background-color: rgba($color: #505050, $alpha: 0.6);
  }

  @include canhover {
    transition: background-color 0.2s $quint-ease-out;

    &:hover {
        background-color: rgba($color: #505050, $alpha: 0.6);
    }
}
}

@keyframes flickerMovement {
    0% {
      opacity: 0.97861;
      transform: translate(0.4389924193300864px, 0)
    }
    5% {
      opacity: 0.94769;
      transform: translate(0.3928974010788217px, 0.07882px)
    }
    10% {
      opacity: 0.93604;
      transform: translate(0.02956275843481219px, 0.0921853855px)
    }
    15% {
      opacity: 1;
      transform: translate(0.30218538552878136px, 0.081758434px)
    }
    20% {
      opacity: 0.78128;
      transform: translate(0.2794037899852017px, 0.04011495)
    }
    25% {
      opacity: 0.93891;
      transform: translate(0.3125630401149584px, 0.0155900)
    }
    30% {
      opacity: 0.85583;
      transform: translate(0.4015590085143956px, 0.1404px)
    }
    35% {
      opacity: 0.97807;
      transform: translate(0.396914047650351px, 0.063269px)
    }
    40% {
      opacity: 0.96559;
      transform: translate(0.370905614848819px, 0.056963px)
    }
    45% {
      opacity: 0.94693;
      transform: translate(0.231056963361899px, 0.0808)
    }
    50% {
      opacity: 1;
      transform: translate(0.08084290417898504px, 0.075435px)
    }
    55% {
      opacity: 0.88594;
      transform: translate(0, 0);
    }
    60% {
      opacity: 0.90313;
    }
    65% {
      opacity: 0.81988;
    }
    70% {
      opacity: 0.93455;
    }
    75% {
      opacity: 0.91428;
    }
    80% {
      opacity: 0.91428;
    }
    85% {
      opacity: 0.90419;
    }
    90% {
      opacity: 1;
    }
    95% {
      opacity: 0.99428;
    }
    100% {
      opacity: 1;
    }
  }