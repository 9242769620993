@mixin placeholder {
    &::-webkit-input-placeholder {
        color: $white;
        opacity: 1;
        text-transform: uppercase;
    }

    &::-moz-placeholder {
        color: $white;
        opacity: 1;
        text-transform: uppercase;
    }
    &:-ms-input-placeholder {
        color: $white;
        opacity: 1;
        text-transform: uppercase;
    }
    &:-moz-placeholder {
        color: $white;
        opacity: 1;
        text-transform: uppercase;
    }
}

// Aspect Ratio
// ex. @include aspect-ratio(16 9);
@mixin aspect-ratio($ratio: 1 1) {
    @if length($ratio) < 2 or length($ratio) > 2 {
        @warn "$ratio must be a list with two values.";
    }

    $width: 100%;
    $height: percentage(nth($ratio, 2) / nth($ratio, 1));

    width: $width;
    height: 0;
    padding-bottom: $height;
}

/**
 * The breakpoint variables.
 */
$break-320: 320px;
$break-360: 360px;
$break-480: 480px;
$break-600: 600px;
$break-768: 768px;
$break-1024: 1024px;
$break-1280: 1280px;
$break-1440: 1440px;
$break-1600: 1600px;
$break-1920: 1920px;

/**
  * The breakpoint mixin.
  */
@mixin breakpoint($point) {
    @if $point == mobileSmall {
        @media (min-width: $break-360) {
            @content;
        }
    } @else if $point == mobileMiddle {
        @media (min-width: $break-480) {
            @content;
        }
    } @else if $point == mobileLarge {
        @media (min-width: $break-600) {
            @content;
        }
    } @else if $point == tabletPortrait {
        @media (min-width: $break-768) {
            @content;
        }
    } @else if $point == tabletLandscape {
        @media (min-width: $break-1024) {
            @content;
        }
    } @else if $point == desktop {
        @media (min-width: $break-1280) {
            @content;
        }
    } @else if $point == desktopMedium {
        @media (min-width: $break-1440) {
            @content;
        }
    } @else if $point == desktopLarge {
        @media (min-width: $break-1600) {
            @content;
        }
    } @else if $point == desktopFullHd {
        @media (min-width: $break-1920) {
            @content;
        }
    }

    // Smartphones (portrait and landscape)
    @else if $point == mobile {
        @media only screen and (min-device-width: $break-320) and (max-device-width: $break-480) {
            @content;
        }
    }
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin canhover {
    @media (hover: hover) {
        @content;
    }
}