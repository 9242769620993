
.preloader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #61766F;
    z-index: 1000;
    position: absolute;
    font-size: 18px;
}

.preloader__line {
    height: 2px;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    max-width: 300px;
}
.preloader__line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF6E2;
    opacity: 0.25;
}
.preloader__active-line {
    opacity: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left center;
    transform: scaleX(0);
    background-color: #FFF6E2;
    transition: transform 0.2s ease;
}

.preloader__next-button {
    text-decoration: underline;
    opacity: 0;
    cursor: pointer;
}

.preloader__inner {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
}

.preloader__logo {
    display: block;
    width: 82px;
    height: 82px;
    object-fit: contain;
    max-width: 60%;
    margin-bottom: 30px;
}

.preloader__progress {
    text-transform: uppercase;
    color: $white;
    font-size: 15px;
    font-weight: 700;
}