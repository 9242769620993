.form {
    width: 85%;
    margin: 0 auto;
}

.form__input {
    display: block;
    width: 100%;
    font-size: em(16px);
    background: $dark-grey;
    border: none;
    border: 2px solid $dark-grey;
    border-radius: 0;
    padding: 1.5% 2%;
    margin-bottom: 2.5%;
    color: $white;
    transition: border-color 0.2s ease;
    @include placeholder;
    text-transform: uppercase;

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        font-size: 100%;
    }

    &--textarea {
        resize: none;
        height: 24%;
    }

    &:focus {
        outline: none;
        border-color: $copper;
    }

    &.form__error-message {
        color: red;
        border-color: red
    }
}

.form__checkbox {
    margin-bottom: 4.5%;

    &--first {
        margin-top: 4%;
    }

    &.form__error-message {
        .checkbox__label::before {
            border: 1px solid red
        }
    }
}
