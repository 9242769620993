.scene {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: visible;
    top: 50px;

    @media screen and (min-width: 996px) {
      top: 80px;
    }

    &.scene--transforms {
        transform: translate(0, -32vw) scale(0.47);
        margin-top: 6vw;

        @media screen and (min-width: 768px) and (orientation: portrait) {
            margin-top: 5vw;
            transform: translate(0, -10vw) scale(0.42);
        }

        @media screen and (min-width: 1024px) and (orientation: landscape) {
            margin-top: -0.5vw;
            transform: translate(0, -10vw) scale(0.42);
        }

        @media screen and (min-width: 1200px) and (orientation: landscape) {
            margin-top: -1vw;
            transform: translate(0, -9.5vw) scale(0.44);
        }
    }
}

.scene__background {
    position: absolute;
    left: 50%;
    top: -142.8vw;
    margin-left: -409vw;
    display: block;
    width: 818vw;
    height: auto;

    @media screen and (min-width: 768px) and (orientation: portrait) {
        width: 574vw;
        margin-left: -287vw;
        top: -93.5vw;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        width: 355vw;
        margin-left: -177.5vw;
        top: -57.5vw;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        width: 250vw;
        margin-left: -125vw;
        top: -41vw;
    }
}

.scene__machine {
    width: 100%;
    height: auto;
}

.scene__machine-processor-holder {
    position: absolute;
    top: 4vw;
    left: 10.5vw;
    display: block;
    width: 79.4830vw;
    height: auto;
    overflow: hidden;

    @media screen and (min-width: 768px) and (orientation: portrait) {
        top: 8vw;
        width: 56.1644vw;
        left: 21.65vw;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        top: 4vw;
        left: 32.4219vw;
        width: 35.0586vw;
    }
    
    @media screen and (min-width: 1200px) and (orientation: landscape) {
        top: 3.4vw;
        width: 24.4532vw;
        left: 37.7734vw;
    }
}

.scene__machine-processor {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.scene__machine-image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.scene__machine-processor-screen-left {
    position: absolute;
    z-index: 1;
    left: 8.3%;
    top: 38.4%;
    width: 39.8%;
}

.scene__screen {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 29.2vw;
    left: 16.2vw;
    width: 67.6vw;
    height: 96.9vw;
    background-color: $off-black;
    border-radius: 6vw;
    overflow: hidden;

    @media screen and (min-width: 768px) and (orientation: portrait) {
        top: 25.8vw;
        left: 25.85vw;
        width: 47.55vw;
        height: 68.3vw;
        border-radius: 4vw;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        top: 15.15vw;
        left: 35.2vw;
        width: 29.5vw;
        height: 42.65vw;
        border-radius: 2vw;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        top: 11.13vw;
        left: 39.65vw;
        width: 20.65vw;
        height: 29.75vw;
        border-radius: 1.6vw;
    }
}

.scene__screen-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
}

.scene__screen-on {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white;
    transform: scale(0);
}

.scene__plants-left {
    position: absolute;
    top: -79.5vw;
    left: -192vw;
    display: block;
    width: 224.4619vw;
    height: auto;
    z-index: 500;
    pointer-events: none;
    transform: translateX(-10%);

    @media screen and (min-width: 768px) and (orientation: portrait) {
        top: -53.78vw;
        left: -121.9vw;
        width: 158.6097vw;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        top: -34.3vw;
        left: -57vw;
        width: 99.0063vw;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        top: -22.5vw;
        left: -24.4vw;
        width: 69.0564vw;
    }
}

.scene__plants-right {
    position: absolute;
    top: -129vw;
    left: 52vw;
    display: block;
    width: 217.4475vw;
    height: auto;
    z-index: 500;
    pointer-events: none;
    transform: translateX(10%);

    @media screen and (min-width: 768px) and (orientation: portrait) {
        top: -84.2vw;
        left: 51vw;
        width: 153.6531vw;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        top: -53vw;
        left: 51vw;
        width: 95.9124vw;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        top: -36.6vw;
        left: 50.5vw;
        width: 66.8984vw;
    }
}

.overlay-blur-holder {
    width: 100%;
    height: calc(100% - 50px) !important;
    position: fixed;
    left: 0;
    top: 50px;
    z-index: 9999;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) and (orientation: portrait) {
      top: 50px;
      height: calc(100% - 50px) !important;
    }

    @media screen and (min-width: 996px) {
      top: 80px;
      height: calc(100% - 80px) !important;
    }
}

.overlay-blur {
    background: rgba(255, 255, 255, 0.2); // Make sure this color has an opacity of less than 1
    backdrop-filter: blur(5px); // This be the blur
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 0;
}

#overlay-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
}

.overlay-dark {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.2;
}

.overlay-blur-inner {
    position: relative;
    height: calc(100% - 100px);
    max-height: 925px;
    transform: translateY(150%);
    margin-top: 12px;

    @media screen and (min-width: 768px) and (orientation: portrait) {
      height: calc(100% - 120px);
      margin-top: 15px;
    }

    @media screen and (min-width: 996px) {
      height: calc(100% - 120px);
      margin-top: 20px;
    }
}

.overlay__recipe {
    position: relative;
    display: block;
    width: auto;
    height: calc(100% - 126px);
    margin: 0 auto;
    max-height: 798px;
}

.overlay__content {
    margin-top: 30px;
    text-align: center;
}

.overlay__content-reveal {
    opacity: 0;
    transform: translateY(20%);
}
  .crt::after {
    content: " ";
    display: block;
    position: absolute;
    width: 98%;
    height: 98%;
    top: 1%;
    left: 1%;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 20;
    pointer-events: none;
    animation: flicker 0.15s infinite;
    border-radius: 1.6vw;
  }
  .crt::before {
    content: " ";
    display: block;
    position: absolute;
    width: 98%;
    height: 98%;
    top: 1%;
    left: 1%;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 20;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
    border-radius: 1.6vw;
    opacity: 0.6;
  }
  .textshadow {
    animation: textShadow 1.6s infinite;
  }
  .textshadow-inverse {
    animation: textShadowInverse 1.6s infinite;
  }
  .multiselect__input {
    animation: textShadow 1.6s infinite;
  }

  .multiselect__tag,.multiselect__option--selected {
    animation: textShadowLess 1.6s infinite;
  }
  


@keyframes flicker {
    0% {
      opacity: 0.27861;
    }
    5% {
      opacity: 0.34769;
    }
    10% {
      opacity: 0.23604;
    }
    15% {
      opacity: 0.90626;
    }
    20% {
      opacity: 0.18128;
    }
    25% {
      opacity: 0.83891;
    }
    30% {
      opacity: 0.65583;
    }
    35% {
      opacity: 0.67807;
    }
    40% {
      opacity: 0.26559;
    }
    45% {
      opacity: 0.84693;
    }
    50% {
      opacity: 0.96019;
    }
    55% {
      opacity: 0.08594;
    }
    60% {
      opacity: 0.20313;
    }
    65% {
      opacity: 0.71988;
    }
    70% {
      opacity: 0.53455;
    }
    75% {
      opacity: 0.37288;
    }
    80% {
      opacity: 0.71428;
    }
    85% {
      opacity: 0.70419;
    }
    90% {
      opacity: 0.7003;
    }
    95% {
      opacity: 0.36108;
    }
    100% {
      opacity: 0.24387;
    }
}
@keyframes textShadow {
    0% {
      text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80, 0.3), 0 0 3px;
    }
    5% {
      text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    10% {
      text-shadow: 0.02956275843481219px 0 1px rgba(0,30,255,0.5), -0.02956275843481219px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    15% {
      text-shadow: 0.40218538552878136px 0 1px rgba(0,30,255,0.5), -0.40218538552878136px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    20% {
      text-shadow: 3.4794037899852017px 0 1px rgba(0,30,255,0.5), -3.4794037899852017px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    25% {
      text-shadow: 1.6125630401149584px 0 1px rgba(0,30,255,0.5), -1.6125630401149584px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    30% {
      text-shadow: 0.7015590085143956px 0 1px rgba(0,30,255,0.5), -0.7015590085143956px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    35% {
      text-shadow: 3.896914047650351px 0 1px rgba(0,30,255,0.5), -3.896914047650351px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    40% {
      text-shadow: 3.870905614848819px 0 1px rgba(0,30,255,0.5), -3.870905614848819px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    45% {
      text-shadow: 2.231056963361899px 0 1px rgba(0,30,255,0.5), -2.231056963361899px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    50% {
      text-shadow: 0.08084290417898504px 0 1px rgba(0,30,255,0.5), -0.08084290417898504px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    55% {
      text-shadow: 2.3758461067427543px 0 1px rgba(0,30,255,0.5), -2.3758461067427543px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    60% {
      text-shadow: 2.202193051050636px 0 1px rgba(0,30,255,0.5), -2.202193051050636px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    65% {
      text-shadow: 2.8638780614874975px 0 1px rgba(0,30,255,0.5), -2.8638780614874975px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    70% {
      text-shadow: 0.48874025155497314px 0 1px rgba(0,30,255,0.5), -0.48874025155497314px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    75% {
      text-shadow: 1.8948491305757957px 0 1px rgba(0,30,255,0.5), -1.8948491305757957px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    80% {
      text-shadow: 0.0833037308038857px 0 1px rgba(0,30,255,0.5), -0.0833037308038857px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    85% {
      text-shadow: 0.09769827255241735px 0 1px rgba(0,30,255,0.5), -0.09769827255241735px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    90% {
      text-shadow: 3.443339761481782px 0 1px rgba(0,30,255,0.5), -3.443339761481782px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    95% {
      text-shadow: 2.1841838852799786px 0 1px rgba(0,30,255,0.5), -2.1841838852799786px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
    100% {
      text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px;
    }
}

@keyframes textShadowLess {
    0% {
      text-shadow: 0.4389924193300864px 0 0.5px rgba(0,30,255,0.2), -0.4389924193300864px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    5% {
      text-shadow: 1.7928974010788217px 0 0.5px rgba(0,30,255,0.2), -1.7928974010788217px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    10% {
      text-shadow: 0.02956275843481219px 0 0.5px rgba(0,30,255,0.2), -0.02956275843481219px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    15% {
      text-shadow: 0.40218538552878136px 0 0.5px rgba(0,30,255,0.2), -0.40218538552878136px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    20% {
      text-shadow: 1.4794037899852017px 0 0.5px rgba(0,30,255,0.2), -1.4794037899852017px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    25% {
      text-shadow: 0.6125630401149584px 0 0.5px rgba(0,30,255,0.2), -0.6125630401149584px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    30% {
      text-shadow: 0.7015590085143956px 0 0.5px rgba(0,30,255,0.2), -0.7015590085143956px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    35% {
      text-shadow: 1.896914047650351px 0 0.5px rgba(0,30,255,0.2), -1.896914047650351px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    40% {
      text-shadow: 1.870905614848819px 0 0.5px rgba(0,30,255,0.2), -1.870905614848819px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    45% {
      text-shadow: 1.231056963361899px 0 0.5px rgba(0,30,255,0.2), -1.231056963361899px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    50% {
      text-shadow: 0.08084290417898504px 0 0.5px rgba(0,30,255,0.2), -0.08084290417898504px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    55% {
      text-shadow: 1.3758461067427543px 0 0.5px rgba(0,30,255,0.2), -1.3758461067427543px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    60% {
      text-shadow: 1.202193051050636px 0 0.5px rgba(0,30,255,0.2), -1.202193051050636px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    65% {
      text-shadow: 1.8638780614874975px 0 0.5px rgba(0,30,255,0.2), -1.8638780614874975px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    70% {
      text-shadow: 0.48874025155497314px 0 0.5px rgba(0,30,255,0.2), -0.48874025155497314px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    75% {
      text-shadow: 0.8948491305757957px 0 0.5px rgba(0,30,255,0.2), -0.8948491305757957px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    80% {
      text-shadow: 0.0833037308038857px 0 0.5px rgba(0,30,255,0.2), -0.0833037308038857px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    85% {
      text-shadow: 0.09769827255241735px 0 0.5px rgba(0,30,255,0.2), -0.09769827255241735px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    90% {
      text-shadow: 1.443339761481782px 0 0.5px rgba(0,30,255,0.2), -1.443339761481782px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    95% {
      text-shadow: 1.1841838852799786px 0 0.5px rgba(0,30,255,0.2), -1.1841838852799786px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
    100% {
      text-shadow: 1.6208764473832513px 0 0.5px rgba(0,30,255,0.2), -1.6208764473832513px 0 0.5px rgba(255,0,80,0.3), 0 0 1px;
    }
}

@keyframes textShadowInverse {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(210, 216, 255, 0.5), -0.4389924193300864px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(210, 216, 255, 0.5), -2.7928974010788217px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(210, 216, 255, 0.5), -0.02956275843481219px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(210, 216, 255, 0.5), -0.40218538552878136px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(210, 216, 255, 0.5), -3.4794037899852017px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(210, 216, 255, 0.5), -1.6125630401149584px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(210, 216, 255, 0.5), -0.7015590085143956px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(210, 216, 255, 0.5), -3.896914047650351px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(210, 216, 255, 0.5), -3.870905614848819px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(210, 216, 255, 0.5), -2.231056963361899px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(210, 216, 255, 0.5), -0.08084290417898504px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(210, 216, 255, 0.5), -2.3758461067427543px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(210, 216, 255, 0.5), -2.202193051050636px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(210, 216, 255, 0.5), -2.8638780614874975px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(210, 216, 255, 0.5), -0.48874025155497314px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(210, 216, 255, 0.5), -1.8948491305757957px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(210, 216, 255, 0.5), -0.0833037308038857px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(210, 216, 255, 0.5), -0.09769827255241735px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(210, 216, 255, 0.5), -3.443339761481782px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(210, 216, 255, 0.5), -2.1841838852799786px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(210, 216, 255, 0.5), -2.6208764473832513px 0 1px rgba(255, 163, 192,0.3), 0 0 3px;
  }
}