h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font;
    margin: 0;
    padding: 0;
    font-weight: normal;
}

p {
    margin: 0;
}
