*,
*:before,
*:after {
    box-sizing: border-box;
}

[v-cloak] {
    display: none !important;
}

// * {
//     -khtml-user-select: none;
//     -moz-user-select: -moz-none;
//     -o-user-select: none;
//     user-select: none;
// }

html,
body {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 100%;
    font-style: normal;
    font-family: $text-font;
    font-weight: 400;
    // color: $font-color;
    background-color: #efeeea;
    color: $font-color;
    overflow: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.scroll-lock {
    overflow: hidden;
}

#app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

#__nuxt {
    height: 100%;
}

#__layout {
    height: 100%;
}

.wrapper {
    height: 100%;
}

::selection {
    background: $white;
    color: $black;
}

::-moz-selection {
    background: $white;
    color: $black;
}

[role="button"] {
    cursor: pointer;
}
