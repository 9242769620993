:root {
    --background-color: #ffffff;
}

$white: #ffffff;
$black: #000000;

$off-black: #272727;
$dark-grey: #333333;
$green: #61766f;
$yellow: #f4d940;
$copper: #d49074;

$body-bg: $white;
$font-color: $green;

// Text Styles
$title-font: 'NespressoLucas-XtraBd', sans-serif;
$text-font: 'NespressoLucas-Bold', sans-serif;
$medium-font: 'NespressoLucas-Med', sans-serif;

$quint-ease-out: cubic-bezier(0.22, 1, 0.36, 1);
$quint-ease-in-out: cubic-bezier((0.83, 0, 0.17, 1));
$cubic-ease-out: cubic-bezier(0.33, 1, 0.68, 1);
$cubic-ease-in-out: cubic-bezier(0.65, 0, 0.35, 1);
