@font-face {
    font-family: "NespressoLucas-Bold";
    src: url("https://nespresso-b2c.s3.eu-central-1.amazonaws.com/nes-3477-b2c-landingpagina-ambassador-exclusives/media/fonts/NespressoLucas-Bold.woff2")
            format("woff2"),
        url("https://nespresso-b2c.s3.eu-central-1.amazonaws.com/nes-3477-b2c-landingpagina-ambassador-exclusives/media/fonts/NespressoLucas-Bold.woff")
            format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "NespressoLucas-XtraBd";
    src: url("https://nespresso-b2c.s3.eu-central-1.amazonaws.com/nes-3477-b2c-landingpagina-ambassador-exclusives/media/fonts/NespressoLucas-XtraBd.woff2")
            format("woff2"),
        url("https://nespresso-b2c.s3.eu-central-1.amazonaws.com/nes-3477-b2c-landingpagina-ambassador-exclusives/media/fonts/NespressoLucas-XtraBd.woff")
            format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "NespressoLucas-Med";
    src: url("https://nespresso-b2c.s3.eu-central-1.amazonaws.com/nes-3477-b2c-landingpagina-ambassador-exclusives/media/fonts/NespressoLucas-Med.woff2")
            format("woff2"),
        url("https://nespresso-b2c.s3.eu-central-1.amazonaws.com/nes-3477-b2c-landingpagina-ambassador-exclusives/media/fonts/NespressoLucas-Med.woff")
            format("woff");
    font-weight: 500;
    font-style: normal;
}