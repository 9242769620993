.checkbox-holder {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.checkbox-container {
    position: relative;
    display: block;
}

.checkbox {
    display: none;
}

.checkbox:checked + .checkbox__label .checkbox__icon {
    display: block;
}

.checkbox__label {
    width: 100%;
    height: 20%;
    display: block;
    font-size: 70%;
    position: relative;
    opacity: 1;
    line-height: 1;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    text-align: left;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 6%;
        height: auto;
        padding-bottom: 6%;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: $dark-grey;
    }
}

.checkbox__icon {
    position: absolute;
    top: 50%;
    right: 0.7%;
    width: 4.5%;
    height: auto;
    display: none;
    transform: translateY(-50%);
}

.checkbox__link {
    color: $white;
}