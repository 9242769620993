.artist-selection {
    position: absolute;
    top: 40%;
    width: 86%;

    &--selected {
        .multiselect__content-wrapper {
            display: none;
        }

        .multiselect__input, .multiselect__single {
            display: none;
        }

        .multiselect__select:before {
            display: none;
        }

        .multiselect__current, .multiselect__select {
            display: none;
        }
    }

    &--not-empty {
        .multiselect__content-wrapper {
            max-height: 300% !important;
        }
    }
}

.multiselect {
    color: $white !important;
    font-size: inherit !important;
}

.multiselect__select {
    top: 50% !important;
    transform: translateY(-50%) !important;

    &::before {
        position: absolute !important;
        margin-top: 0 !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        right: 15px !important;
    }
}

.multiselect--active .multiselect__select {
    transform: translateY(-50%) rotate(180deg) !important;
}

.multiselect--above .multiselect__content-wrapper {
    bottom: auto !important;
}

.multiselect__tags {
    background: $dark-grey !important;
    border-color: $dark-grey !important;
    border-radius: 0 !important;
}

.multiselect__content-wrapper {
    // display: block !important;
    max-height: 286% !important;
    margin-top: 4%;
    background: $dark-grey !important;
    border: none !important;
    border-radius: 0 !important;

    @media screen and (min-width: 360px) {
        max-height: 362% !important;
    }

    @media screen and (min-width: 375px) {
        max-height: 367% !important;
    }

    @media screen and (min-width: 410px) {
        max-height: 387% !important;
    }

    @media screen and (min-width: 768px) and (orientation: portrait) {
        max-height: 537% !important;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        max-height: 470% !important;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        max-height: 371% !important;
    }

    @media screen and (min-width: 1300px) and (orientation: landscape) {
        max-height: 371% !important;
    }

    @media screen and (min-width: 1400px) and (orientation: landscape) {
        max-height: 452% !important;
    }

    @media screen and (min-width: 1500px) and (orientation: landscape) {
        max-height: 474% !important;
    }

    @media screen and (min-width: 1600px) and (orientation: landscape) {
        max-height: 515% !important;
    }

    @media screen and (min-width: 1800px) and (orientation: landscape) {
        max-height: 600% !important;
    }
}

.artist-selection--not-empty .multiselect__content-wrapper {
    max-height: 194% !important;
    
    @media screen and (min-width: 360px) {
        max-height: 210% !important;
    }

    @media screen and (min-width: 375px) {
        max-height: 203% !important;
    }

    @media screen and (min-width: 410px) {
        max-height: 252% !important;
    }

    @media screen and (min-width: 768px) and (orientation: portrait) {
        max-height: 327% !important;
    }

    @media screen and (min-width: 1024px) and (orientation: landscape) {
        max-height: 259% !important;
    }

    @media screen and (min-width: 1200px) and (orientation: landscape) {
        max-height: 200% !important;
    }

    @media screen and (min-width: 1300px) and (orientation: landscape) {
        max-height: 200% !important;
    }

    @media screen and (min-width: 1400px) and (orientation: landscape) {
        max-height: 256% !important;
    }

    @media screen and (min-width: 1500px) and (orientation: landscape) {
        max-height: 260% !important;
    }

    @media screen and (min-width: 1600px) and (orientation: landscape) {
        max-height: 274% !important;
    }

    @media screen and (min-width: 1800px) and (orientation: landscape) {
        max-height: 301% !important;
    }
}

.multiselect__element {
    -webkit-tap-highlight-color: transparent !important;
    outline: none !important;
}

.multiselect__option {
    text-transform: uppercase !important;
    min-height: auto !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: none !important;
}

.multiselect__placeholder {
    display: none !important;
    font-size: 115% !important;
    color: rgba($color: $white, $alpha: 0.6) !important;
    text-transform: uppercase;
    margin-left: 0.95%;
    padding-top: 0% !important;
    margin-bottom: 2.2% !important;
    line-height: 1 !important;
    height: 1vw !important;
}

.multiselect__input, .multiselect__single {
    width: 100% !important;
    position: relative !important;
    background: $dark-grey !important;
    color: $white !important;
    text-transform: uppercase;
    padding-top: 0% !important;
    margin-bottom: 2.2% !important;
    font-size: 115% !important;
    line-height: 1 !important;
    padding-left: 0.95% !important;

    &::-webkit-input-placeholder {
        color: $white !important;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: $white !important;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $white !important;
        opacity: 1;
    }
    &:-moz-placeholder {
        color: $white !important;
        opacity: 1;
    }
}

.mobile-device .multiselect__input,
.mobile-device .multiselect__single {
    font-size: 16px !important;

    &::-webkit-input-placeholder {
        font-size: 12px !important;
    }

    &::-moz-placeholder {
        font-size: 12px !important;
    }

    &:-ms-input-placeholder {
        font-size: 12px !important;
    }

    &:-moz-placeholder {
        font-size: 12px !important;
    }
}

.multiselect__tags {
    font-size: 90% !important;
}

.multiselect__tag {
    background: $copper !important;
    text-transform: uppercase !important;
    border-radius: 0 !important;
    padding-left: 6px !important;
    padding-right: 21px !important;
}

.multiselect__tag-icon {
    right: 4px !important;
    width: 16px !important;
}

.multiselect__tag-icon:after {
    position: relative;
    display: block !important;
    content: '' !important;
    color: $white !important;
    top: 0;
    font-size: 62% !important;
    width: auto !important;
    height: 100% !important;
    background: url('media/images/remove.svg');
    background-repeat: no-repeat;
    background-size: auto 30%;
    background-position: center center;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: $copper !important;
}

.multiselect__option {
    padding: 3% !important;
    font-size: 104% !important;
}

.multiselect__option--selected {
    background: $copper !important;
    color: $white !important;
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: transparent !important;
}

.multiselect__option--highlight {
    background: transparent !important;
}

.multiselect__option--highlight:after {
    background: transparent !important;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: $copper !important;
    color: $white !important;
}

.multiselect__maximum-message {
    display: none;
}

.screen__selection-overview-title-btn {
    position: absolute;
    top: 79%;
}

.mobile-device .multiselect__option--selected.multiselect__option--highlight:after,
.mobile-device .multiselect__option--highlight:after {
    display: none !important;
}

@media (hover: none) {
    .multiselect__option--selected.multiselect__option--highlight:after,
    .multiselect__option--highlight:after {
        display: none !important;
    }
}

.multiselect__option:after {
    @media screen and (min-width: 1080px) and (orientation: landscape) {
        top: 45% !important;
        transform: translateY(-50%) !important;
        line-height: normal !important;
    }

    @media screen and (min-width: 1280px) and (orientation: landscape) {
        top: 43% !important;
    }

    @media screen and (min-width: 1400px) and (orientation: landscape) {
        top: 44% !important;
    }

    @media screen and (min-width: 1600px) and (orientation: landscape) {
        top: 45% !important;
    }

    @media screen and (min-width: 1800px) and (orientation: landscape) {
        top: 46% !important;
    }

    @media screen and (min-width: 1920px) and (orientation: landscape) {
        top: 43% !important;
    }
}

.multiselect__option--selected.multiselect__option--highlight:after {
    @media screen and (min-width: 1080px) and (orientation: landscape) {
        font-size: 18px !important;
        top: 45% !important;
        transform: translateY(-50%) !important;
        line-height: normal !important;
        padding-right: 13px !important;
    }

    @media screen and (min-width: 1280px) and (orientation: landscape) {
        top: 43% !important;
    }

    @media screen and (min-width: 1400px) and (orientation: landscape) {
        top: 44% !important;
    }

    @media screen and (min-width: 1600px) and (orientation: landscape) {
        top: 45% !important;
    }

    @media screen and (min-width: 1800px) and (orientation: landscape) {
        padding-right: 14px !important;
        top: 46% !important;
    }

    @media screen and (min-width: 1920px) and (orientation: landscape) {
        top: 45% !important;
    }
}