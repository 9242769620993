.overlay-holder {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    background-color: rgba($color: $black, $alpha: 0.4);

    @media screen and (min-width: 996px) {
        top: 80px;
        height: calc(100% - 80px);
    }
}

.overlay__inner {
    padding: 30px 20px;
    display: flex;
    width: calc(100% - 50px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    text-align: center;
    max-width: 400px;
    border-radius: 14px;
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.2);

    @include breakpoint(mobileMiddle) {
        width: 90%;
        padding: 40px 30px;
    }
}

.overlay__btn {
    @media screen and (max-width: 667px) {
        padding: 0 25px;
        height: 50px;
    }
}

.overlay__logo {
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;

    @include breakpoint(tabletPortrait) {
        width: 60px;
        height: 60px;
        margin-bottom: 30px;
    }
}

.overlay__title {
    text-transform: uppercase;
    color: #26211e;
    font-weight: bold;
    font-size: 7.5vw;
    margin-bottom: 15px;
    line-height: 1.15;
    
    @include breakpoint(mobileMiddle) {
        font-size: 8vw;
    }

    @media screen and (min-width: 400px) {
        font-size: 32px;
    }

    @include breakpoint(tabletPortrait) {
        font-size: 32px;
        margin-bottom: 20px;
    }
}

.overlay__text {
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #26211e;
    
    @include breakpoint(tabletPortrait) {
        margin-bottom: 20px;
    }
}

.overlay__btn {
    margin-top: 15px;
    color: inherit;
    text-decoration: none;
}